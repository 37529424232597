import React from 'react';
import './Materials.css';

function Materials() {
  return (
    <div className="materials-overview">
      <h2>Fund Facts</h2>
      <a href='/files/fundFacts.pdf' target='_blank' rel='noopener noreferrer'><p>RCP Fund Facts, September 2024.</p></a>
      <h2>Monthly Performance Reports</h2>
      <a href='/files/septReport.pdf' target='_blank' rel='noopener noreferrer'><p>October 18, 2024. Investor and Performance Report.</p></a>
    </div>
  );
}

export default Materials;
