import React from 'react';
import './Fund.css';

function Fund() {
  return (
    <div className="fund-overview">
    <h2>The Fund</h2>
        <p>
        RCP's fund is unaffliated with Western University and the Ivey Business School. 
        RCP operates as an independent entity and raises its own capital from outside investors.
        The Fund has a time horizon of eight months for the majority of its investments, looking to capitalize on market trends.
        The Fund is organized into fourteen teams: seven equity teams, two fixed income and macro teams, two commodity teams, one FX team, one quant team, and one portoflio/risk management team.
        </p>
    <h2>Investment Process</h2>
        <p>
        RCP employs a dual-focus on fundamentals and quantitative analysis when approaching every investment opportunity.
        Each idea begins with a fundamental screening process to find investments that are trading below their intrinsic value.
        Quantitative research and analysis is then conducted to identify entry and exit levels, as well as to manage risk.
        Our analysts, across fourteen teams, then conduct an extensive research procedure to understand and evaluate the company. 
        Investments that pass the research process are then pitched at full-fund meetings or in investment Committee meetings.
        RCP strives to complete the research process thoroughly and in a timely fashion to ensure that the fund is able to capitalize on the investment opportunity.
        All trades go through multiple levels of approval before being executed in the fund's main trading account.
        Pitch decks and perofrmance reports are then created and made available to investors and the general public on a monthly basis.
        </p>
      </div>
  );
}

export default Fund;
