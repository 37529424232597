import React from 'react';
import './Team.css'; // We'll define styles here

const leadership = [
  {
    name: 'Wilson Boyle',
    title: 'Managing Partner,<br />Head of Macro Strategy',
    image: '/headshots/wilson-boyle.png',
  },
  {
    name: 'Noah Jina',
    title: 'Managing Partner,<br />Head of Quantitative Strategies',
    image: '/headshots/noah-jina.jpeg',
  },
  {
    name: 'Jaden Kim',
    title: 'Managing Partner,<br />Head of Commodities',
    image: '/headshots/jaden-kim.png',
  },
  {
    name: 'Christian Goff',
    title: 'Head of Equities',
    image: '/headshots/christian-goff.jpeg',
  },
  {
    name: 'Abby Mclellan',
    title: 'Chief Operating Officer',
    image: '/headshots/abby-mclellan.jpg',
  },
];

const heads = [
    {
        name: 'Cooper Perron',
        title: 'Oil',
        image: 'path_to_image/tim-cook.jpg',
      },
      {
        name: 'Aidan Zia',
        title: 'Consumer Staples',
        image: 'path_to_image/katherine-adams.jpg',
      },
      {
        name: 'Eshan Rasul',
        title: 'Consumer Staples',
        image: 'path_to_image/eddy-cue.jpg',
      },
      {
        name: 'Adam Dorget',
        title: 'TMT',
        image: 'path_to_image/eddy-cue.jpg',
      },
      {
        name: 'Abby Mclellan',
        title: 'Chief Operating Officer',
        image: 'path_to_image/eddy-cue.jpg',
      },
];

const legal = [
    {
        name: 'Kennedy Borland',
        title: 'Legal Counsel',
        image: 'path_to_image/tim-cook.jpg',
      },
      {
        name: 'Josh',
        title: 'Legal Counsel',
        image: 'path_to_image/katherine-adams.jpg',
      },
]

const analysts = [
    {
      name: 'Cooper Perron',
      title: 'Oil',
      image: 'path_to_image/tim-cook.jpg',
    },
    {
      name: 'Aidan Zia',
      title: 'Consumer Staples',
      image: 'path_to_image/katherine-adams.jpg',
    },
    {
      name: 'Eshan Rasul',
      title: 'Consumer Staples',
      image: 'path_to_image/eddy-cue.jpg',
    },
    {
      name: 'Adam Dorget',
      title: 'TMT',
      image: 'path_to_image/eddy-cue.jpg',
    },
    {
      name: 'Abby Mclellan',
      title: 'Chief Operating Officer',
      image: 'path_to_image/eddy-cue.jpg',
    },
];

function Team() {
  return (
    <div className="team-page">
      <h2>Leadership Team</h2>
      <div className="team-section">
        {leadership.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="member-image" />
            <h3>{member.name}</h3>
            <p dangerouslySetInnerHTML={{ __html: member.title }} />
          </div>
        ))}
      </div>

      {/* <h2>Investment Leads</h2>
      <div className="team-section">
        {heads.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="member-image" />
            <h3>{member.name}</h3>
            <p>{member.title}</p>
          </div>
        ))}
        </div>

      <h2>Legal Team</h2>
      <div className="team-section">
        {legal.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="member-image" />
            <h3>{member.name}</h3>
            <p>{member.title}</p>
          </div>
        ))}
        </div>

      <h2>Analysts</h2>
      <div className="team-section">
        {analysts.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="member-image" />
            <h3>{member.name}</h3>
            <p>{member.title}</p>
          </div>
        ))}
        </div> */}

    </div>
  );
}

export default Team;
