import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-overview">
    <h2>About RCP</h2>
        <p>
          Richmond Capital Partners (“RCP”) is Western University’s largest student-managed fund by assets under management. 
          Established in 2023 by Jonathan Hader and Benjamin Fletcher, the fund’s primary mandate is to educate students at Western / Ivey and provide practical, 
          hands-on experience in a buy-side environment. RCP hosts weekly meetings and pitch presentation, allowing students to present research on a variety of assets
          with the possibility of them being added to a real portoflio. RCP currently operates fourteen teams across multiple equity sectors and asset classes,
          giving investors exposure to a wide array of investment opportunities.
        </p>
      </div>
  );
}

export default About;
