import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logoRect from '../assets/logo-rect.png';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to close the menu when a nav link is clicked
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="main-header">
      <div className="headerContainer">
        <Link to="/" className="logo-container" onClick={closeMenu}>
          <img src={logoRect} alt="Logo" className="logo" />
        </Link>
        <button className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li><Link to="/about" onClick={closeMenu}>About</Link></li>
            <li><Link to="/team" onClick={closeMenu}>Team</Link></li>
            <li><Link to="/fund" onClick={closeMenu}>The Fund</Link></li>
            <li><Link to="/materials" onClick={closeMenu}>Materials</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
