import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Materials from './pages/Materials';
import About from './pages/About';
import Team from './pages/Team';
import Fund from './pages/Fund';

function App() {
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/materials" element={<Materials />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/fund" element={<Fund />} />
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
